import React from "react"
import ResponseB from "../../components/body/pages/newsletter-email/responseB"
import NoheaderFooter from "../../components/Noheaderfooter"
import SEO from "../../components/seo"

const ResponseBRoute = () => (
  <NoheaderFooter>
    <SEO title="Kuda Monthly Newsletter" />
    <ResponseB />
  </NoheaderFooter>
)
export default ResponseBRoute
